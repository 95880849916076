<template>
  <el-form :model="form" label-position="top" ref="form">
    <eden-form-section title="" subtitle="">
      <template slot="form-fields">
        <el-row type="flex" :gutter="20" class="is-flex-wrap">
          <el-col :md="12">
            <el-form-item
              label="First name"
              prop="first_name"
              :rules="validateName()"
            >
              <el-input
                type="text"
                v-model="form.first_name"
                placeholder="Enter first name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Last name"
              prop="last_name"
              :rules="validateName()"
            >
              <el-input
                type="text"
                v-model="form.last_name"
                placeholder="Enter last name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Email address"
              prop="email"
              :rules="validateEmail()"
            >
              <el-input
                type="text"
                v-model="form.email"
                placeholder="you@mail.com"
                :disabled="action === 'edit'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Phone number"
              prop="phoneNo"
              :rules="validatePhone()"
            >
              <eden-phone-input :input.sync="form.phoneNo" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20" class="is-flex-wrap">
          <el-col :md="12">
            <el-form-item
              label="Location area"
              prop="location_area_id"
              :rules="validateField()"
            >
              <el-select
                v-model="form.location_area_id"
                filterable
                placeholder="Select location"
              >
                <el-option
                  v-for="location in locations"
                  :key="location.id"
                  :label="location.name"
                  :value="location.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Gender"
              prop="gender"
              :rules="validateField('Gender')"
            >
              <el-select v-model="form.gender" placeholder="Select gender">
                <el-option label="Male" value="male"></el-option>
                <el-option label="Female" value="female"></el-option>
                <el-option label="Non binary" value="non-binary"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section
      title="Other"
      subtitle="Enter some extra information about this customer"
    >
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Address"
              prop="address"
              :rules="validateField()"
            >
              <el-input
                type="textarea"
                rows="4"
                v-model="form.address"
                placeholder="Enter the customer’s full address"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Landmark (optional)">
              <el-input
                type="textarea"
                rows="4"
                v-model="form.landmark"
                placeholder="Closest known location to the customers house"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Access note (optional)">
              <el-input
                type="textarea"
                rows="4"
                v-model="form.access_note"
                placeholder="Specific instructions on getting access to the customers house"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Gardener in charge"
              prop="gardener_id"
              :rules="validateField()"
            >
              <el-select
                v-model="form.gardener_id"
                placeholder="Select gardener"
                filterable
              >
                <el-option
                  v-for="(gardener, index) in gardeners"
                  :key="index"
                  :label="gardener.name"
                  :value="gardener.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section title="" subtitle="">
      <template slot="form-fields">
        <div class="eden-page-form__actions">
          <el-button plain @click="$router.go(-1)">Cancel</el-button>
          <el-button
            v-if="action === 'add'"
            type="primary"
            :loading="adding"
            @click="addNewCustomer"
            >Add Customer</el-button
          >
        </div>
      </template>
    </eden-form-section>
  </el-form>
</template>

<script>
import onetime from "@/requests/customers/onetime";
import * as actions from "@/store/action-types";

export default {
  name: "CustomersOnetimeIndividualForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      form: {
        avatar: this.getDefaultAvatarUrl(),
        first_name: "",
        last_name: "",
        email: "",
        phoneNo: "",
        address: "",
        landmark: "",
        access_note: "",
        location_area_id: "",
        gender: "",
        gardener_id: "",
        one_time_customer: true,
        role: "customer",
      },
      adding: false,
    };
  },
  computed: {
    locations() {
      return this.$store.getters.location_areas_list;
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  created() {
    this.$store.dispatch(actions.GET_GARDENERS_LIST);
  },
  methods: {
    formatForm() {
      const payload = this.parseData(this.form);
      payload.full_name = payload.first_name + " " + payload.last_name;
      return payload;
    },
    addNewCustomer() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;
        const payload = this.formatForm();

        onetime
          .add(payload)
          .then((response) => {
            const { status, data, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "customers.individual",
                params: { id: data.user_id },
              });
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
