<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="'One Time - Add new customer'"
    />
    <customers-onetime-form />
  </div>
</template>

<script>
import CustomersOnetimeForm from "@/components/Customers/Onetime/CustomerOnetimeForm";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerOnetimeAdd",
  components: {
    CustomersOnetimeForm,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>

<style scoped lang="scss"></style>
