<template>
  <el-form :model="form" label-position="top" ref="form">
    <eden-form-section title="" subtitle="">
      <template slot="form-fields">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Company name"
              prop="company_name"
              :rules="validateName()"
            >
              <el-input
                type="text"
                v-model="form.company_name"
                placeholder="Enter company name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Location area"
              prop="location_area_id"
              :rules="validateField()"
            >
              <el-select
                v-model="form.location_area_id"
                filterable
                placeholder="Select location"
              >
                <el-option
                  v-for="location in locations"
                  :key="location.id"
                  :label="location.name"
                  :value="location.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Address"
              prop="company_address"
              :rules="validateField()"
            >
              <el-input
                type="textarea"
                rows="4"
                v-model="form.company_address"
                placeholder="Enter the company’s full address"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section
      title="Contact Person"
      subtitle="Information about Eden’s contact person at this company."
    >
      <template slot="form-fields">
        <el-row type="flex" :gutter="20" class="is-flex-wrap">
          <el-col :md="12">
            <el-form-item
              label="First name"
              prop="contact_person_first_name"
              :rules="validateName()"
            >
              <el-input
                type="text"
                v-model="form.contact_person_first_name"
                placeholder="Enter first name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="Last name"
              prop="contact_person_last_name"
              :rules="validateName()"
            >
              <el-input
                type="text"
                v-model="form.contact_person_last_name"
                placeholder="Enter last name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Email"
              prop="contact_person_email_address"
              :rules="validateEmail()"
            >
              <el-input
                type="text"
                v-model="form.contact_person_email_address"
                placeholder="you@mail.com"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Phone number"
              prop="contact_person_phone_number"
              :rules="validatePhone()"
            >
              <eden-phone-input
                :input.sync="form.contact_person_phone_number"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <eden-form-section title="" subtitle="">
      <template slot="form-fields">
        <div class="eden-page-form__actions">
          <el-button plain @click="goHome">Cancel</el-button>
          <el-button
            type="primary"
            :loading="adding"
            @click="addOnetimeCustomer"
            >Add Customer</el-button
          >
        </div>
      </template>
    </eden-form-section>
  </el-form>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomersOnetimeOrganisationForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        company_name: "",
        company_address: "",
        location_area_id: "",
        contact_person_first_name: "",
        contact_person_last_name: "",
        contact_person_email_address: "",
        contact_person_phone_number: "",
        corporate_persona_id: "",
        one_time_customer: true,
      },
      adding: false,
    };
  },
  computed: {
    locations() {
      return this.$store.getters.location_areas_list;
    },
    services() {
      return ["Carebox", "Cleaning", "Laundry", "Meal"];
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: "customers.index" });
    },
    addOnetimeCustomer() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;
        const payload = this.form;

        corporate
          .add(payload)
          .then((response) => {
            const { status, data, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.adding = false;
              this.$router.push({
                name: "customers.corporate",
                params: { id: data.user_id },
              });
            } else {
              this.adding = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
