<template>
  <div class="eden-page-form">
    <eden-form-section
      title="Customer Details"
      subtitle="Let’s get to know this customer"
      class="mb-20"
    >
      <template slot="form-fields">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Customer Type">
                <el-radio-group
                  v-model="form.customer_type"
                  class="eden-switch-radio small"
                >
                  <el-radio-button label="individual"
                    >Individual</el-radio-button
                  >
                  <el-radio-button label="corporate">Corporate</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </eden-form-section>
    <template v-if="form.customer_type === 'individual'">
      <customers-onetime-individual-form />
    </template>
    <template v-else>
      <customers-onetime-corporate-form />
    </template>
  </div>
</template>

<script>
import CustomersOnetimeIndividualForm from "@/components/Customers/Onetime/CustomersOnetimeIndividualForm";
import CustomersOnetimeCorporateForm from "@/components/Customers/Onetime/CustomersOnetimeCorporateForm";

export default {
  name: "CustomersOnetimeForm",
  components: {
    CustomersOnetimeIndividualForm,
    CustomersOnetimeCorporateForm,
  },
  data() {
    return {
      form: {
        customer_type: "individual",
      },
      adding: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
